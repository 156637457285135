<template>
  <main>
  </main>
</template>

<route>
{
"meta": {
"label": "會員錢包"
}
}
</route>

<script>
import District from '@/assets/json/Districts.json'
export default {
  name: 'ListMemberWallet',
  layout: 'manage',
  components: {
    // Multiselect: () => import('@/plugins/mutiselect'),
    // InfiniteLoading: () => import('vue-infinite-loading'),
  },
  data() {
    return {
      List: [],
      User: {},
      UserData: {
        Tags: [],
        Groups: [],
        Variable: {}
      },
      SearchFilter: {
        Email: [],
        Class: ''
      },
      District,
      FilterModel: false,
      ShowFilter: 'All',
      PasswordVisible: false,
      Action: 'Update',
      CreateUserModel: false,
      AlertModel: false,
      ChooseUsers: [],
      CheckAllValue: false,
      NextPageToken: null,
      Loading: false,
      Submit: false,
      MemberGroup: [],
      noItemsView: {
        noResults: this.$t('Organization/Member.NoResults'),
        noItems: this.$t('Organization/Member.NoItems')
      },
      FilterEmail: ''
    }
  },
  computed: {
    Field() {
      return [
        { key: 'Checkbox', label: '' },
        { key: 'Email', label: this.$t('Organization.Email') },
        { key: 'Name', label: this.$t('Organization.Name') },
        { key: 'creationTime', label: this.$t('Organization.CreateTime') },
        { key: 'lastSignInTime', label: this.$t('Organization.LastLoginTime') },
        { key: 'disabled', label: this.$t('Organization.Status') },
        { key: 'emailVerified', label: this.$t('Organization.EmailVerified') },
        { key: 'Action', label: '' }
      ]
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    Init() {
      return Promise.all([
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    }
  }
}
</script>

<style lang="scss">
#ChooseNotice {
  position: absolute;
  z-index: 10;
  width: 100%;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#MemberList  {
  thead {
    th {
      white-space: nowrap;
      &:first-child {
        text-align: center;
      }
    }
  }
  tbody {
    td {
      white-space: nowrap;
      &:first-child {
        vertical-align: middle;
      }
    }
  }
}
</style>
